/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add  to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g )
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";

// To import images
const images = require.context('../images', true);

require("metismenu")
require("@rails/activestorage").start()
require("clipboard")
require("channels")
require("bootstrap")

window.jQuery = $;
window.$ = $;
require('select2')
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

window.addEventListener('DOMContentLoaded', () => {
    $('.select2').select2({ theme: "bootstrap" });

    // Function called on <textarea class="expand"> to show an expand animation on focusing
    $('textarea.expand').focus(function () {
        $(this).animate({ height: "8em" }, 500);
    });
});

import { Application } from "@hotwired/stimulus"
import AbsencesController from "./controllers/absences_controller"
import DishesController from "./controllers/dishes_controller"

window.Stimulus = Application.start();
Stimulus.register('absences', AbsencesController);
Stimulus.register('dishes', DishesController);
