import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', function () {
  const group_id = $('#channel-group-id').data('group-id');

  if (group_id === undefined) { return; }

  consumer.subscriptions.create({ channel: "BpChannel", group_id: group_id}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      window.location.reload();
    }
  });
})
