import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', function () {
  const user_id = $('#channel-note-user-id').data('user-id');

  if (user_id === undefined) { return; }

  consumer.subscriptions.create({ channel: "FeedbackNoteChannel", user_id: user_id}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      $('#user-notes').prepend(data.message)
    }
  });
})
