import { Controller } from "@hotwired/stimulus"
// import {isBlank} from "../lib/utilities";

// Connects to data-controller="absences"
export default class extends Controller {
  static targets = ['rangeSelector', 'startDateInput', 'endDateInput', 'rangeInput', 'commentInput', 'typeInput'];

  connect() {
    this.toggleRangeSelector();
    this.toggleCommentRequired();
  }

  toggleRangeSelector() {
    let display = 'none';

    if (this.startDateInputTarget.value === this.endDateInputTarget.value) {
      display = 'block';
    }
    this.rangeSelectorTarget.style.display = display;
    if (this.typeInputTarget.value !== 'remote') {
      this.rangeInputTarget.value = 'full_day';
    }
  }

  toggleCommentRequired() {
    let required = true;

    if (this.typeInputTarget.value === 'remote') {
      required = false;
    }

    this.commentInputTarget.required = required;
    this.commentInputTarget.parentNode.querySelector('label abbr').style.display = required ? 'inline' : 'none';
  }
}
