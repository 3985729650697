import { Controller } from "@hotwired/stimulus"
import {isBlank} from "../lib/utilities";
import Fuse from 'fuse.js'

// Connects to data-controller="dishes"
export default class extends Controller {
  search(event) {
    const word = event.currentTarget.value;
    const cardTitles = Array.from(this.element.querySelectorAll('[data-dishes-title]'));
    const fuseOptions = {
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["innerText"]
    };
    const fuse = new Fuse(cardTitles, fuseOptions);
    const validCardTitles = fuse.search(word).map((result) => result.item);

    cardTitles.forEach((cardTitle) => {
      if (isBlank(word) || validCardTitles.includes(cardTitle)) {
        cardTitle.closest('[data-dishes-card]').style.display = 'block';
      } else {
        cardTitle.closest('[data-dishes-card]').style.display = 'none';
      }
    });
  }
}
