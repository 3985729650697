export const isUndefined = (obj) => obj === undefined;
export const isNull = (obj) => obj === null;
export const isNullOrUndefined = (obj) => isNull(obj) || isUndefined(obj);
export const isNotNullOrUndefined = (obj) => !isNullOrUndefined(obj);
export const setWithDefault = (value, _default) => isUndefined(value) || isNull(value) ? _default : value;
export const isPresent = (obj) => !isNullOrUndefined(obj) && obj !== '' && obj !== [] && obj !== {} && ((typeof obj === 'string' && !obj.match(/^\s*$/)) || true);
export const isBlank = (obj) => !isPresent(obj);

// Grab the CSRF token from the meta tag
export const csrfToken = () => {
  const csrfTokenTag = document.querySelector("[name='csrf-token']");
  return isNullOrUndefined(csrfTokenTag) ? null : csrfTokenTag.content;
}

export const capitalize = (word) => {
  return `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`;
}

export const camelize = (text, separator = '_') => {
  const words = text.split(separator)
  const result = [capitalize(words[0])]
  words.slice(1).forEach((word) => result.push(capitalize(word)))
  return result.join('')
}

export const randomInteger = () => Math.floor(Math.random() * 10000);

export const fetchStream = async (url, successCallback, options, body, errorCallback, catchCallback) => {
  if (isNullOrUndefined(options)) {
    options = {
      method: 'GET'
    };
  }

  if (isNullOrUndefined(options.method)) {
    options.method = 'GET'
  }

  if (isNullOrUndefined(options.headers)) {
    options.headers = {
      "X-CSRF-Token": csrfToken()
    }
  }

  if (isNullOrUndefined(options.headers['X-CSRF-Token'])) {
    options.headers["X-CSRF-Token"] = csrfToken();
  }

  if (isNotNullOrUndefined(body)) {
    if (body instanceof FormData || typeof (body) === 'string') {
      options.body = body;
    } else if (body instanceof Object) {
      options.body = JSON.stringify(body)
      options.headers['Content-Type'] = 'application/json';
    }
  }

  if (isNullOrUndefined(catchCallback)) {
    if (isNullOrUndefined(errorCallback)) {
      catchCallback = (error) => {
        console.log("Fatal error", error);
      }
    } else {
      catchCallback = errorCallback;
    }
  }

  if (isNullOrUndefined(errorCallback)) {
    errorCallback = (response) => {
      console.log("An error occured", response);
    }
  }

  const response = await fetch(url, options).catch(catchCallback);
  const data = await response.text();
  const contentType = (await response).headers.get('Content-Type');
  const jsonMatcher = new RegExp('application/json');
  const xmlMatcher = new RegExp('application/xml');
  let parsedData;

  if (jsonMatcher.test(contentType)) {
    parsedData = JSON.parse(data);
  } else if (xmlMatcher.test(contentType)) {
    const parser = new DOMParser();

    parsedData = parser.parseFromString(data, "application/xml");
  } else {
    parsedData = data;
  }

  if (response.status >= 200 && response.status < 300) {
    successCallback(parsedData)
  } else {
    errorCallback(parsedData);
  }
}

export const formattedSize = (size) => {
  if (size < 1024) {
    return `${size} octets`;
  }
  if (size < 1024 * 1024) {
    return `${Math.ceil(size / 1024)} Ko`;
  }
  if (size < 1024 * 1024 * 1024) {
    return `${Math.ceil(size / 1024 / 1024)} Mo`;
  }
  if (size < 1024 * 1024 * 1024 * 1024) {
    return `${Math.ceil(size / 1024 / 1024 / 1024)} Go`;
  }
}

export const executeIfAllowed = (callback) => {
  grecaptcha.ready(function () {
    grecaptcha.execute(window.recaptcha_site_key, {action: 'action'}).then(function (token) {
      console.log("Checking captcha");

      fetch(`/partials/check_allowed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-Token": csrfToken()
        },
        body: JSON.stringify({recaptcha_token: token})
      }).then((response) => {
        console.log(`Captcha checked: status = ${response.status}`);

        if (response.status >= 200 && response.status < 300) {
          callback.call(response);
        }
      }).catch((error) => {
        console.log("Captcha failed with error:", error);
        // Noop
      });
    })
  })
}

export const validationAlert = async (message) => {
  return new Promise((resolve) => {
    const confirmation = window.confirm(message);
    if (confirmation) {
      resolve(true);
    }
  });
};

export const dateTimeString = (date) => {
  if (isNullOrUndefined(date)) {
    date = new Date();
  }

  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();
  const hh = String(date.getHours()).padStart(2, '0');
  const MM = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');

  return `${dd}/${mm}/${yyyy} ${hh}:${MM}:${ss}`;
}

export const UUIDv4 = new function () {
  function generateNumber(limit) {
    const value = limit * Math.random();
    return value | 0;
  }

  function generateX() {
    const value = generateNumber(16);
    return value.toString(16);
  }

  function generateXes(count) {
    let result = '';
    for (let i = 0; i < count; ++i) {
      result += generateX();
    }
    return result;
  }

  function generateVariant() {
    const value = generateNumber(16);
    const variant = (value & 0x3) | 0x8;
    return variant.toString(16);
  }

  // UUID v4
  //
  //   varsion: M=4
  //   variant: N
  //   pattern: xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
  //
  this.generate = function () {
    return generateXes(8)
      + '-' + generateXes(4)
      + '-' + '4' + generateXes(3)
      + '-' + generateVariant() + generateXes(3)
      + '-' + generateXes(12)
  };
};

export const displayAlert = (message, alertType) => {
  const allowedAlertTypes = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
  let validAlertType = allowedAlertTypes.find((aT) => aT === alertType);

  if (isNullOrUndefined(validAlertType)) {
    validAlertType = 'light';
  }

  document.getElementById('errors').innerHTML = `
      <div data-controller="flashes" class="alert alert-${validAlertType} alert-dismissible fade show my-0" role="alert">
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `;
}

